import { ImageLoading, TypographyVariants, getUrlForAssets } from "../../Contracts";

import Typography from "../Typography";

import styles from "./Review.module.scss";

export const Review = (props) => {

  if (!props.content || !props.starCount) {
    return null;
  }

  let { starCount = 5 } = props;
  if (starCount < 0 || starCount > 5) {
    starCount = 5;
  }

  const onClick = (event) => {
    if (typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };

  const onMouseEnter = (event) => {
    if (typeof props.onMouseEnter === 'function') {
      props.onMouseEnter(event);
    }
  };

  const onMouseLeave = (event) => {
    if (typeof props.onMouseLeave === 'function') {
      props.onMouseLeave(event);
    }
  };

  return (
    <div
      id={props.id}
      className={`${styles.ReviewContainer} ${props.className ? props.className : ''}`}
      style={props.style ? props.style : {}}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Typography
        variant={TypographyVariants.PARAGRAPH}
        className={styles.ReviewContent}
        style={{ 'fontSize': '16px', 'fontWeight': '400', '-webkitLineClamp': props.reviewContentMaxLineCount > 0 ? props.reviewContentMaxLineCount : 6 }}
        tabletStyle={{ fontSize: '15px' }}
        mobileStyle={{ fontSize: '14px' }}
      >
        {props.content}
      </Typography>
      <div className={`${styles.ReviewUserContainer} ${props.reviewUserClassName ? props.reviewUserClassName : ''}`}>
        {(props.flagIconUrl || props.flagCountry) && (
          <div className={styles.ReviewUserCountry}>
            {props.flagIconUrl && (
              <img src={getUrlForAssets(props.flagIconUrl)} alt="flag" className={styles.ReviewUserCountryFlag} loading={ImageLoading.LAZY} height={props.flagIconHeight || "100%"} width={props.flagIconWidth || "100%"} />
            )}
            {props.flagCountry && (
              <Typography
                variant={TypographyVariants.DIV}
                style={{ fontSize: '16px', fontWeight: '700' }}
                tabletStyle={{ fontSize: '15px' }}
                mobileStyle={{ fontSize: '14px' }}
                className={styles.ReviewUserCountryName}
              >
                {props.flagCountry}
              </Typography>
            )}
          </div>
        )}
        <div className={styles.ReviewStarContainer}>
          {Array(5).fill(0).map((_star, index) => {
            return (
              <img key={index} src={props.starCount === 5 ? getUrlForAssets('/assets/star_filled.svg') : ((index + 1) > starCount ? getUrlForAssets('/assets/star.svg') : getUrlForAssets('/assets/star_filled.svg'))} alt="star" className={styles.ReviewStarIcon} loading={ImageLoading.LAZY} height="21" width="23" />
            );
          })}
        </div>
      </div>
    </div>
  );

};

export default Review;
