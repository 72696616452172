import { Fragment } from "react";

import Head from "next/head";

import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import HomePage from "../components/HomePage";

export const Home = ({ headerData, headerUseCasesSubMenuData, homePageData, footerData, geoIp }) => {
  return (
    <Fragment>
      <Head>
        <title>QuickSell - E-commerce enablement platform for WhatsApp-first businesses</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      <Header headerData={headerData} headerUseCasesSubMenuData={headerUseCasesSubMenuData} geoIp={geoIp} />
      <div className="quicksell-page">
        <HomePage homePageData={homePageData} geoIp={geoIp} />
        <Footer footerData={footerData} geoIp={geoIp} />
      </div>
    </Fragment>
  );
};

export default Home;
