export const SliderRightArrow = (props) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="207px" height="207px" viewBox="0 0 207 207" id={props.id} className={props.className} style={props.style ? props.style : {}}>
      <defs>
        <circle id="path-1" cx="37.5" cy="37.5" r="37.5"></circle>
        <filter x="-141.3%" y="-138.7%" width="382.7%" height="382.7%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="35" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.556357563   0 0 0 0 0.556357563   0 0 0 0 0.556357563  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1265.000000, -142.000000)">
          <g transform="translate(1331.000000, 206.000000)">
            <g>
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
              <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
            </g>
            <g transform="translate(32.000000, 25.000000)" fill="#2B8863" fillRule="nonzero">
              <path d="M3.375,26.3961186 L14.420625,14.95023 C15.193125,14.1498856 15.193125,12.8519778 14.420625,12.0516334 L3.375,0.60381627 C2.13,-0.686377407 0,0.227750848 0,2.05407882 L0,24.9477845 C0,26.772184 2.13,27.6863122 3.375,26.3961186 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SliderRightArrow;
