import { Fragment, useRef, useState, useEffect } from "react";

import debounce from 'lodash.debounce';

import SliderLeftArrow from "../Common/Icons/SliderLeftArrow";
import SliderRightArrow from "../Common/Icons/SliderRightArrow";
import AppStoreLogo from "../Common/Icons/AppStoreLogo";
import PlayStoreLogo from "../Common/Icons/PlayStoreLogo";

import Button from '../Common/Button';
import Card from '../Common/Card';
import ImageSlider from "../Common/ImageSlider";
import Review from "../Common/Review";
import PageLinks from "../Common/PageLinks";
import Typography from "../Common/Typography";

import { Position, Orientation, FlexAlignment, ImageLoading, TypographyVariants, TypographyFonts, getMergedQueryParamsUrl, getUrlForAssets, ButtonType } from "../Contracts";

import styles from './HomePage.module.scss';

export const HomePage = (props) => {

  const videoPlayListimageSliderRef = useRef(null);

  const [browserWindow, setBrowserWindow] = useState({});
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResizeEvent = debounce((event) => {
    if (event && event.type === 'resize') {
      setWindowWidth(event.target.innerWidth);
    }
  }, 200);

  useEffect(() => {
    setBrowserWindow(window);
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  const { homePageData = {} } = props;

  const {
    boostConversionWhatsappSection = {},
    partnerLogosSection = {},
    boostConversionPersonalCommerceSection = {},
    builtWithQuickSellSection = {},
    catalogueECommerceSection = {},
    scaleBusinessOnWhatsappSection = {},
    powerfulFeaturesForB2bSection = {},
    buildYourOwnECommerceAppSection = {},
    playstoreReviewsSection = {},
    videoPlayListSection = {},
    talkToAnExpertSection = {}
  } = homePageData;

  const getPartnerLogosListLength = (featuresList = []) => {
    let featuresListLength = Math.min(featuresList.length, 8);
    if (windowWidth <= parseFloat(styles['variables-tabletMediaQueryMinWidth'])) {
      // Mobile
      featuresListLength = Math.max(featuresListLength, 8);
    } else if (windowWidth > parseFloat(styles['variables-tabletMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-tabletMediaQueryMaxWidth'])) {
      // Tablet
      featuresListLength = 10;
      if (featuresList.length <= featuresListLength / 2) {
        featuresListLength = featuresList.length * 2;
      }
    } else if (windowWidth > parseFloat(styles['variables-desktopMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-largeDesktopMediaQueryMinWidth'])) {
      // Small Desktop
      featuresListLength = 12;
      if (featuresList.length <= featuresListLength / 2) {
        featuresListLength = featuresList.length * 2;
      }
    } else if (windowWidth > parseFloat(styles['variables-largeDesktopMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-veryLargeDesktopMediaQueryMinWidth'])) {
      // Large Desktop
      featuresListLength = 14;
      if (featuresList.length <= featuresListLength / 2) {
        featuresListLength = featuresList.length * 2;
      }
    } else if (windowWidth > parseFloat(styles['variables-veryLargeDesktopMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-veryLargeDesktopMediaQueryMaxWidth'])) {
      // Very Large Desktop
      featuresListLength = 16;
      if (featuresList.length <= featuresListLength / 2) {
        featuresListLength = featuresList.length * 2;
      }
    } else if (windowWidth > parseFloat(styles['variables-veryLargeDesktopMediaQueryMaxWidth'])) {
      // Largest Desktop
      featuresListLength = 18;
      if (featuresList.length <= featuresListLength / 2) {
        featuresListLength = featuresList.length * 2;
      }
    }
    return featuresListLength;
  };

  const getBuiltWithQuicksellCataloguesListLength = () => {
    let featuresListLength = { rows: 5, columns: 2 };
    if (windowWidth > parseFloat(styles['variables-tabletMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-tabletMediaQueryMaxWidth'])) {
      // Tablet
      featuresListLength = { rows: 2, columns: 5 };
    } else if (windowWidth > parseFloat(styles['variables-desktopMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-largeDesktopMediaQueryMinWidth'])) {
      // Small Desktop
      featuresListLength = { rows: 2, columns: 5 };
    } else if (windowWidth > parseFloat(styles['variables-largeDesktopMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-veryLargeDesktopMediaQueryMinWidth'])) {
      // Large Desktop
      featuresListLength = { rows: 2, columns: 6 };
    } else if (windowWidth > parseFloat(styles['variables-veryLargeDesktopMediaQueryMinWidth']) && windowWidth <= parseFloat(styles['variables-veryLargeDesktopMediaQueryMaxWidth'])) {
      // Very Large Desktop
      featuresListLength = { rows: 2, columns: 6 };
    } else if (windowWidth > parseFloat(styles['variables-veryLargeDesktopMediaQueryMaxWidth'])) {
      // Largest Desktop
      featuresListLength = { rows: 2, columns: 7 };
    }
    return featuresListLength;
  };

  const renderBoostConversionWhatsappContainer = () => {
    const { cardTitle, cardDescription, cardImageUrl, cardImageUrlFallback, cardImageHeight, cardImageAlt, cardImageWidth, buttonsList = [], linksList = [] } = boostConversionWhatsappSection || {};
    return (
      <section className={`${styles.BoostConversionWhatsappContainer} page-section-padding`}>
        <Card
          orientation={Orientation.HORIZONTAL}
          imagePosition={{
            mobile: Position.LEFT,
            desktop: Position.RIGHT
          }}
          alignment={{
            justifyContent: FlexAlignment.FLEX_START,
            alignItems: FlexAlignment.CENTER
          }}
          cardImage={{
            className: styles.HomePageCardImage,
            imageUrl: cardImageUrl,
            imageUrlFallback: cardImageUrlFallback,
            imageAlt: cardImageAlt,
            imageHeight: cardImageHeight,
            imageWidth: cardImageWidth,
            imageLoading: ImageLoading.EAGER
          }}
          cardContent={{
            className: styles.HomePageCardContent,
            titleClassName: styles.HomePageCardContentTitle,
            descriptionClassName: styles.HomePageCardContentDescription,
            title: (
              cardTitle ? (
                <Typography
                  variant={TypographyVariants.HEADING_1}
                  className={styles.HomePageCardTitle}
                  style={{ fontSize: '42px', lineHeight: '54px', fontWeight: '700', color: 'var(--black)' }}
                  tabletStyle={{ fontSize: '36px', lineHeight: '46px' }}
                  mobileStyle={{ fontSize: '30px', lineHeight: '39px' }}
                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                />
              ) : null
            ),
            description: (
              <Fragment>
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.HEADING_2}
                    className={styles.HomePageCardDescription}
                    style={{ fontSize: '26px', lineHeight: '39px', fontWeight: '400', color: 'var(--cardDescription)', marginTop: '18px' }}
                    tabletStyle={{ fontSize: '24px', lineHeight: '36px', marginTop: '12px' }}
                    mobileStyle={{ fontSize: '22px', lineHeight: '33px', marginTop: '12px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {buttonsList.length > 0 && (
                  <div className={styles.HomePageCardButtonsContainer}>
                    {buttonsList.map((button, index) => {
                      const { type, htmlType, linkUrl, openInNewTab, text, onClick } = button;
                      return (
                        <Button
                          key={index}
                          type={type}
                          htmlType={htmlType}
                          className={styles.HomePageCardButton}
                          buttonLinkClassName={styles.HomePageCardButtonLink}
                          buttonTextClassName={styles.HomePageCardButtonText}
                          linkUrl={linkUrl}
                          openInNewTab={openInNewTab}
                          onClick={onClick}
                        >
                          {text}
                        </Button>
                      );
                    })}
                  </div>
                )}
                <div className={`${styles.HomePageCardButtonsContainer} ${styles.AppDownloadButtonsContainer}`}>
                  <Button
                    type={ButtonType.DEFAULT}
                    htmlType="button"
                    className={`${styles.HomePageCardButton} ${styles.AppDownloadButton} ${styles.AndroidDownloadButton}`}
                    buttonLinkClassName={styles.HomePageCardButtonLink}
                    buttonTextClassName={styles.HomePageCardButtonText}
                    linkUrl="/download/android?utm_source=web&amp;utm_medium=quicksell.co&amp;utm_campaign=catalogue_ad_campaign"
                    openInNewTab={true}
                    onClick="captureDownloadAndroidClick"
                  >
                    <div className={styles.AppDownloadButtonIconContainer}>
                      <PlayStoreLogo />
                    </div>
                    <div className={styles.AppDownloadButtonTextContainer}>
                      <Typography
                        variant={TypographyVariants.DIV}
                        style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '400', textAlign: 'left' }}
                        tabletStyle={{ fontSize: '11px', lineHeight: '12px' }}
                        mobileStyle={{ fontSize: '10px', lineHeight: '11px' }}
                      >
                        GET IT ON
                      </Typography>
                      <Typography
                        variant={TypographyVariants.DIV}
                        style={{ fontSize: '18px', lineHeight: '19px', fontWeight: '500', textAlign: 'left' }}
                        tabletStyle={{ fontSize: '14px', lineHeight: '15px' }}
                        mobileStyle={{ fontSize: '13px', lineHeight: '14px' }}
                      >
                        Google Play
                      </Typography>
                    </div>
                  </Button>
                  <Button
                    type={ButtonType.DEFAULT}
                    htmlType="button"
                    className={`${styles.HomePageCardButton} ${styles.AppDownloadButton} ${styles.AppleDownloadButton}`}
                    buttonLinkClassName={styles.HomePageCardButtonLink}
                    buttonTextClassName={styles.HomePageCardButtonText}
                    linkUrl="/download/ios?utm_source=web&amp;utm_medium=quicksell.co&amp;utm_campaign=catalogue_ad_campaign"
                    openInNewTab={true}
                    onClick="captureDownloadIOsClick"
                  >
                    <div className={styles.AppDownloadButtonIconContainer}>
                      <AppStoreLogo fill="var(--white)" />
                    </div>
                    <div className={styles.AppDownloadButtonTextContainer}>
                      <Typography
                        variant={TypographyVariants.DIV}
                        style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '400', textAlign: 'left' }}
                        tabletStyle={{ fontSize: '11px', lineHeight: '12px' }}
                        mobileStyle={{ fontSize: '10px', lineHeight: '11px' }}
                      >
                        AVAILABLE ON
                      </Typography>
                      <Typography
                        variant={TypographyVariants.DIV}
                        style={{ fontSize: '18px', lineHeight: '19px', fontWeight: '500', textAlign: 'left' }}
                        tabletStyle={{ fontSize: '14px', lineHeight: '15px' }}
                        mobileStyle={{ fontSize: '13px', lineHeight: '14px' }}
                      >
                        App Store
                      </Typography>
                    </div>
                  </Button>
                </div>
                {linksList.length > 0 && (
                  <PageLinks links={linksList} className={styles.HomePageLinksContainer} linkClassName={styles.HomePageLink} linkTextClassName={styles.HomePageLinkText} linkIconClassName={styles.HomePageLinkIcon} />
                )}
              </Fragment>
            )
          }}
          className={styles.HomePageCard}
        />
      </section>
    );
  };

  const renderPartnerLogosContainer = () => {
    const { cardTitle, featuresList = [] } = partnerLogosSection || {};
    const featuresListLength = getPartnerLogosListLength(featuresList);
    const slicedFeaturesList = featuresList.slice(0, featuresListLength);
    return (
      <section className={`${styles.PartnerLogosContainer} page-section-padding`}>
        {cardTitle && (
          <Typography
            variant={TypographyVariants.HEADING_2}
            className={styles.PartnerLogosHeading}
            style={{ fontSize: '22px', lineHeight: '29px', color: 'var(--darkGray)', fontStyle: 'italic', fontFamily: TypographyFonts.LIBRE_BODONI, paddingLeft: '96px', paddingRight: '96px', textAlign: 'center' }}
            tabletStyle={{ fontSize: '19px', lineHeight: '29px', paddingLeft: '36px', paddingRight: '36px' }}
            mobileStyle={{ fontSize: '18px', lineHeight: '24px', paddingLeft: '36px', paddingRight: '36px' }}
            dangerouslySetInnerHTML={{ __html: cardTitle }}
          />
        )}
        {slicedFeaturesList.length > 0 && (
          <div className={styles.PartnerLogosListContainer}>
            <div className={styles.PartnerLogosList}>
              {slicedFeaturesList.map((feature, index) => {
                const { cardTitle, cardImageUrl, cardImageHeight, cardImageWidth } = feature;
                return (
                  <img key={index} src={getUrlForAssets(cardImageUrl)} alt={cardTitle} className={styles.PartnerLogo} style={{ width: `calc(100% / ${featuresListLength / 2})` }} loading={ImageLoading.EAGER} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                );
              })}
            </div>
          </div>
        )}
      </section>
    );
  };

  const renderBoostConversionPersonalCommerceContainer = () => {
    const { cardTitle, featuresList = [] } = boostConversionPersonalCommerceSection || {};
    return (
      <section className={`${styles.BoostConversionPersonalCommerceContainer} page-section-padding`}>
        {cardTitle && (
          <Typography
            variant={TypographyVariants.HEADING_2}
            className={styles.BoostConversionPersonalCommerceHeading}
            style={{ fontSize: '34px', fontStyle: 'italic', fontFamily: TypographyFonts.LIBRE_BODONI, textAlign: 'center' }}
            tabletStyle={{ fontSize: '30px' }}
            mobileStyle={{ fontSize: '28px' }}
            dangerouslySetInnerHTML={{ __html: cardTitle }}
          />
        )}
        {featuresList.length > 0 && (
          <div className={styles.BoostConversionPersonalCommerceCardRow}>
            {featuresList.map((feature, index) => {
              const { cardTitle, cardDescription, cardImageUrl, cardImageUrlFallback, cardImageAlt, cardImageHeight, cardImageWidth, linksList = [] } = feature;
              return (
                <div key={index} className={styles.BoostConversionPersonalCommerceCardColumn}>
                  <Card
                    orientation={Orientation.VERTICAL}
                    imagePosition={{
                      mobile: Position.LEFT,
                      desktop: Position.LEFT
                    }}
                    alignment={{
                      justifyContent: FlexAlignment.FLEX_START,
                      alignItems: FlexAlignment.FLEX_START
                    }}
                    cardImage={{
                      className: styles.HomePageCardImage,
                      imageUrl: cardImageUrl,
                      imageUrlFallback: cardImageUrlFallback,
                      imageAlt: cardImageAlt,
                      imageHeight: cardImageHeight,
                      imageWidth: cardImageWidth
                    }}
                    cardContent={{
                      className: styles.HomePageCardContent,
                      title: (
                        cardTitle ? (
                          <Typography
                            variant={TypographyVariants.HEADING_3}
                            className={styles.HomePageCardTitle}
                            style={{ fontSize: '22px', fontWeight: '700', color: 'var(--black)' }}
                            tabletStyle={{ fontSize: '21px' }}
                            mobileStyle={{ fontSize: '20px' }}
                            dangerouslySetInnerHTML={{ __html: cardTitle }}
                          />
                        ) : null
                      ),
                      description: (
                        <Fragment>
                          {cardDescription && (
                            <Typography
                              variant={TypographyVariants.HEADING_4}
                              className={styles.HomePageCardDescription}
                              style={{ fontSize: '19px', color: 'var(--darkGray)' }}
                              tabletStyle={{ fontSize: '18px' }}
                              mobileStyle={{ fontSize: '17px' }}
                              dangerouslySetInnerHTML={{ __html: cardDescription }}
                            />
                          )}
                          {linksList.length > 0 && (
                            <PageLinks links={linksList} className={styles.HomePageLinksContainer} linkClassName={styles.HomePageLink} linkTextClassName={styles.HomePageLinkText} linkIconClassName={styles.HomePageLinkIcon} />
                          )}
                        </Fragment>
                      )
                    }}
                    className={styles.HomePageCard}
                  />
                </div>
              );
            })}
          </div>
        )}
      </section>
    );
  };

  const renderBuiltWithQuickSellContainer = () => {
    const { cardTitle, featuresList = [] } = builtWithQuickSellSection || {};
    const { rows, columns } = getBuiltWithQuicksellCataloguesListLength();
    return (
      <section className={`${styles.BuiltWithQuicksellContainer} page-section-padding`}>
        {cardTitle && (
          <Typography
            variant={TypographyVariants.HEADING_2}
            style={{ fontSize: '30px', fontFamily: TypographyFonts.LIBRE_BODONI, fontStyle: 'italic', marginBottom: '48px', textAlign: 'center' }}
            tabletStyle={{ fontSize: '28px', marginBottom: '36px' }}
            mobileStyle={{ fontSize: '26px', marginBottom: '36px' }}
            dangerouslySetInnerHTML={{ __html: cardTitle }}
          />
        )}
        {(featuresList.slice(0, rows * columns).length > 0 &&
          <div className={styles.BuiltWithQuicksellCataloguesContainer}>
            {featuresList.slice(0, rows * columns).map((feature, index) => {
              const { cardTitle, cardImageUrl, cardImageHeight, cardImageWidth, linkUrl, openInNewTab } = feature;
              return (
                <a
                  key={index}
                  href={getMergedQueryParamsUrl(linkUrl, browserWindow)}
                  target={openInNewTab ? '_blank' : '_self'}
                  rel={openInNewTab ? 'noopener noreferrer' : ''}
                  className={styles.BuiltWithQuicksellCatalogue}
                  style={{ maxWidth: `calc(100% / ${columns})` }}
                >
                  <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                  {cardTitle && (
                    <Typography
                      style={{ fontSize: '20px', textAlign: 'center', marginTop: '24px' }}
                      tabletStyle={{ fontSize: '19px' }}
                      mobileStyle={{ fontSize: '18px' }}
                    >
                      {cardTitle}
                    </Typography>
                  )}
                </a>
              );
            })}
          </div>
        )}
      </section>
    );
  };

  const renderCatalogueECommerceContainer = () => {
    const { cardTitle, cardDescription, cardImageUrl, cardImageUrlFallback, cardImageAlt, cardImageHeight, cardImageWidth, featuresList = [] } = catalogueECommerceSection || {};
    return (
      <section className={`${styles.CatalogueECommerceContainer} page-section-padding`}>
        <Card
          orientation={Orientation.HORIZONTAL}
          imagePosition={{
            mobile: Position.LEFT,
            desktop: Position.RIGHT
          }}
          alignment={{
            justifyContent: FlexAlignment.FLEX_START,
            alignItems: FlexAlignment.CENTER
          }}
          cardImage={{
            className: styles.HomePageCardImage,
            imageUrl: cardImageUrl,
            imageUrlFallback: cardImageUrlFallback,
            imageAlt: cardImageAlt,
            imageHeight: cardImageHeight,
            imageWidth: cardImageWidth
          }}
          cardContent={{
            className: styles.HomePageCardContent,
            title: (
              cardTitle ? (
                <Typography
                  variant={TypographyVariants.HEADING_2}
                  className={styles.HomePageCardTitle}
                  style={{ fontSize: '36px', fontWeight: '700', color: 'var(--black)' }}
                  tabletStyle={{ fontSize: '32px' }}
                  mobileStyle={{ fontSize: '26px' }}
                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                />
              ) : null
            ),
            description: (
              <Fragment>
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.HEADING_3}
                    className={styles.HomePageCardDescription}
                    style={{ fontSize: '26px', marginTop: '24px' }}
                    tabletStyle={{ fontSize: '24px', marginTop: '18px' }}
                    mobileStyle={{ fontSize: '22px', marginTop: '12px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {featuresList.length > 0 && (
                  <div className={styles.HomePageFeaturesContainer}>
                    {featuresList.filter((feature) => feature.cardDescription).length > 0 && (
                      <div className={styles.HomePageFeatureTitlesContainer}>
                        {featuresList.filter((feature) => feature.cardDescription).map((feature, index) => {
                          const { cardImageUrl, cardImageHeight, cardImageWidth, cardTitle, cardDescription, linksList = [] } = feature;
                          return (
                            <div key={index} className={styles.HomePageFeatureTitleContainer}>
                              {cardImageUrl && (
                                <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle || cardDescription} className={styles.HomePageFeatureIcon} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                              )}
                              <div className={styles.HomePageFeatureTextContainer}>
                                {cardTitle && (
                                  <Typography
                                    variant={TypographyVariants.HEADING_4}
                                    className={styles.HomePageFeatureTitle}
                                    dangerouslySetInnerHTML={{ __html: cardTitle }}
                                  />
                                )}
                                {cardDescription && (
                                  <Typography
                                    variant={TypographyVariants.PARAGRAPH}
                                    className={styles.HomePageFeatureText}
                                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                                  />
                                )}
                                {linksList.length > 0 && (
                                  <PageLinks links={linksList} className={styles.HomePageLinksContainer} linkClassName={styles.HomePageLink} linkTextClassName={styles.HomePageLinkText} linkIconClassName={styles.HomePageLinkIcon} />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {featuresList.filter((feature) => !feature.cardDescription).length > 0 && (
                      <div className={styles.HomePageFeatureListContainer}>
                        {featuresList.filter((feature) => !feature.cardDescription).map((feature, index) => {
                          const { cardImageUrl, cardImageHeight, cardImageWidth, cardTitle } = feature;
                          return (
                            <div key={index} className={styles.HomePageFeatureListItemContainer}>
                              {cardImageUrl && (
                                <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle} className={styles.HomePageFeatureListItemIcon} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                              )}
                              {cardTitle && (
                                <Typography
                                  variant={TypographyVariants.HEADING_4}
                                  className={styles.HomePageFeatureListItemText}
                                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            )
          }}
          className={styles.HomePageCard}
        />
      </section>
    );
  };

  const renderScaleBusinessOnWhatsappContainer = () => {
    const { cardTitle, cardDescription, cardImageUrl, cardImageUrlFallback, cardImageAlt, cardImageHeight, cardImageWidth, featuresList = [], linksList = [] } = scaleBusinessOnWhatsappSection || {};
    return (
      <section className={`${styles.ScaleBusinessOnWhatsappContainer} page-section-padding`}>
        <Card
          orientation={Orientation.HORIZONTAL}
          imagePosition={{
            mobile: Position.LEFT,
            desktop: Position.LEFT
          }}
          alignment={{
            justifyContent: FlexAlignment.FLEX_START,
            alignItems: FlexAlignment.CENTER
          }}
          cardImage={{
            className: styles.HomePageCardImage,
            imageUrl: cardImageUrl,
            imageUrlFallback: cardImageUrlFallback,
            imageAlt: cardImageAlt,
            imageHeight: cardImageHeight,
            imageWidth: cardImageWidth
          }}
          cardContent={{
            className: styles.HomePageCardContent,
            title: (
              cardTitle ? (
                <Typography
                  variant={TypographyVariants.HEADING_2}
                  className={styles.HomePageCardTitle}
                  style={{ fontSize: '36px', fontWeight: '700', color: 'var(--black)' }}
                  tabletStyle={{ fontSize: '32px' }}
                  mobileStyle={{ fontSize: '26px' }}
                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                />
              ) : null
            ),
            description: (
              <Fragment>
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.HEADING_3}
                    className={styles.HomePageCardDescription}
                    style={{ fontSize: '26px', marginTop: '24px' }}
                    tabletStyle={{ fontSize: '24px', marginTop: '18px' }}
                    mobileStyle={{ fontSize: '22px', marginTop: '12px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {featuresList.length > 0 && (
                  <div className={styles.HomePageFeaturesContainer}>
                    <div className={styles.HomePageFeatureListContainer}>
                      {featuresList.map((feature, index) => {
                        const { cardImageUrl, cardImageHeight, cardImageWidth, cardTitle } = feature;
                        return (
                          <div key={index} className={styles.HomePageFeatureListItemContainer}>
                            {cardImageUrl && (
                              <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle} className={styles.HomePageFeatureListItemIcon} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                            )}
                            {cardTitle && (
                              <Typography
                                variant={TypographyVariants.PARAGRAPH}
                                className={styles.HomePageFeatureListItemText}
                                dangerouslySetInnerHTML={{ __html: cardTitle }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {linksList.length > 0 && (
                  <PageLinks links={linksList} className={styles.HomePageLinksContainer} linkClassName={styles.HomePageLink} linkTextClassName={styles.HomePageLinkText} linkIconClassName={styles.HomePageLinkIcon} />
                )}
              </Fragment>
            )
          }}
          className={styles.HomePageCard}
        />
      </section>
    );
  };

  const renderPowerfulFeaturesForB2bBusinessContainer = () => {
    const { cardTitle, cardDescription, cardImageUrl, cardImageUrlFallback, cardImageAlt, cardImageHeight, cardImageWidth, featuresList = [], linksList = [] } = powerfulFeaturesForB2bSection || {};
    return (
      <section className={`${styles.PowerfulFeaturesForB2bBusinessContainer} page-section-padding`}>
        <Card
          orientation={Orientation.HORIZONTAL}
          imagePosition={{
            mobile: Position.LEFT,
            desktop: Position.RIGHT
          }}
          alignment={{
            justifyContent: FlexAlignment.FLEX_START,
            alignItems: FlexAlignment.FLEX_START
          }}
          cardImage={{
            className: styles.HomePageCardImage,
            imageUrl: cardImageUrl,
            imageUrlFallback: cardImageUrlFallback,
            imageAlt: cardImageAlt,
            imageHeight: cardImageHeight,
            imageWidth: cardImageWidth
          }}
          cardContent={{
            className: styles.HomePageCardContent,
            title: (
              cardTitle ? (
                <Typography
                  variant={TypographyVariants.HEADING_2}
                  className={styles.HomePageCardTitle}
                  style={{ fontSize: '36px', fontWeight: '700', color: 'var(--black)' }}
                  tabletStyle={{ fontSize: '32px' }}
                  mobileStyle={{ fontSize: '26px' }}
                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                />
              ) : null
            ),
            description: (
              <Fragment>
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.HEADING_3}
                    className={styles.HomePageCardDescription}
                    style={{ fontSize: '26px', marginTop: '24px' }}
                    tabletStyle={{ fontSize: '24px', marginTop: '18px' }}
                    mobileStyle={{ fontSize: '22px', marginTop: '12px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {featuresList.length > 0 && (
                  <div className={styles.HomePageFeaturesContainer}>
                    <div className={styles.HomePageFeatureTitlesContainer}>
                      {featuresList.slice(0, 2).map((feature, index) => {
                        const { cardImageUrl, cardImageHeight, cardImageWidth, cardTitle, cardDescription } = feature;
                        return (
                          <div key={index} className={styles.HomePageFeatureTitleContainer}>
                            {cardImageUrl && (
                              <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle || cardDescription} className={styles.HomePageFeatureIcon} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                            )}
                            {(cardTitle || cardDescription) &&
                              <div className={styles.HomePageFeatureTextContainer}>
                                {cardTitle && (
                                  <Typography
                                    variant={TypographyVariants.HEADING_4}
                                    className={styles.HomePageFeatureTitle}
                                    dangerouslySetInnerHTML={{ __html: cardTitle }}
                                  />
                                )}
                                {cardDescription && (
                                  <Typography
                                    variant={TypographyVariants.PARAGRAPH}
                                    className={styles.HomePageFeatureText}
                                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                                  />
                                )}
                              </div>
                            }
                          </div>
                        );
                      })}
                    </div>
                    <div className={styles.HomePageFeatureTitlesContainer}>
                      {featuresList.slice(2, 4).map((feature, index) => {
                        const { cardImageUrl, cardImageHeight, cardImageWidth, cardTitle, cardDescription } = feature;
                        return (
                          <div key={index} className={styles.HomePageFeatureTitleContainer}>
                            {cardImageUrl && (
                              <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle || cardDescription} className={styles.HomePageFeatureIcon} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                            )}
                            {(cardTitle || cardDescription) &&
                              <div className={styles.HomePageFeatureTextContainer}>
                                {cardTitle && (
                                  <Typography
                                    variant={TypographyVariants.HEADING_4}
                                    className={styles.HomePageFeatureTitle}
                                    dangerouslySetInnerHTML={{ __html: cardTitle }}
                                  />
                                )}
                                {cardDescription && (
                                  <Typography
                                    variant={TypographyVariants.PARAGRAPH}
                                    className={styles.HomePageFeatureText}
                                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                                  />
                                )}
                              </div>
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {linksList.length > 0 && (
                  <PageLinks links={linksList} className={styles.HomePageLinksContainer} linkClassName={styles.HomePageLink} linkTextClassName={styles.HomePageLinkText} linkIconClassName={styles.HomePageLinkIcon} />
                )}
              </Fragment>
            )
          }}
          className={styles.HomePageCard}
        />
      </section>
    );
  };

  const renderBuildYourOwnECommerceAppContainer = () => {
    const { cardTitle, cardDescription, cardVideoUrl, cardVideoAlt, cardVideoHeight, cardVideoWidth, cardVideoTagName, cardVideoControls, cardVideoAutoplay, cardVideoMuted, cardVideoLoop, cardVideoDisablePictureInPicture, cardVideoAllowFullScreen, featuresList = [], linksList = [] } = buildYourOwnECommerceAppSection || {};
    return (
      <section className={`${styles.BuildYourOwnECommereAppContainer} page-section-padding`}>
        <Card
          orientation={Orientation.HORIZONTAL}
          imagePosition={{
            mobile: Position.LEFT,
            desktop: Position.LEFT
          }}
          alignment={{
            justifyContent: FlexAlignment.FLEX_START,
            alignItems: FlexAlignment.CENTER
          }}
          cardImage={{
            className: styles.HomePageCardImage,
            videoUrl: cardVideoUrl,
            videoAlt: cardVideoAlt,
            videoHeight: cardVideoHeight,
            videoWidth: cardVideoWidth,
            videoTagName: cardVideoTagName,
            videoControls: cardVideoControls,
            videoAutoplay: cardVideoAutoplay,
            videoMuted: cardVideoMuted,
            videoLoop: cardVideoLoop,
            videoDisablePictureInPicture: cardVideoDisablePictureInPicture,
            videoAllowFullScreen: cardVideoAllowFullScreen,
            videoLoading: ImageLoading.LAZY
          }}
          cardContent={{
            className: styles.HomePageCardContent,
            title: (
              cardTitle ? (
                <Typography
                  variant={TypographyVariants.HEADING_2}
                  className={styles.HomePageCardTitle}
                  style={{ fontSize: '36px', fontWeight: '700', color: 'var(--black)' }}
                  tabletStyle={{ fontSize: '32px' }}
                  mobileStyle={{ fontSize: '26px' }}
                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                />
              ) : null
            ),
            description: (
              <Fragment>
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.PARAGRAPH}
                    className={styles.HomePageCardDescription}
                    style={{ fontSize: '26px', marginTop: '24px' }}
                    tabletStyle={{ fontSize: '24px', marginTop: '18px' }}
                    mobileStyle={{ fontSize: '22px', marginTop: '12px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {featuresList.length > 0 && (
                  <div className={styles.HomePageFeaturesContainer}>
                    <div className={styles.HomePageFeatureListContainer}>
                      {featuresList.map((feature, index) => {
                        const { cardImageUrl, cardImageHeight, cardImageWidth, cardTitle } = feature;
                        return (
                          <div key={index} className={styles.HomePageFeatureListItemContainer}>
                            {cardImageUrl && (
                              <img src={getUrlForAssets(cardImageUrl)} alt={cardTitle} className={styles.HomePageFeatureListItemIcon} loading={ImageLoading.LAZY} height={cardImageHeight || "100%"} width={cardImageWidth || "100%"} />
                            )}
                            {cardTitle && (
                              <Typography
                                variant={TypographyVariants.PARAGRAPH}
                                className={styles.HomePageFeatureListItemText}
                                dangerouslySetInnerHTML={{ __html: cardTitle }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {linksList.length > 0 && (
                  <PageLinks links={linksList} className={styles.HomePageLinksContainer} linkClassName={styles.HomePageLink} linkTextClassName={styles.HomePageLinkText} linkIconClassName={styles.HomePageLinkIcon} />
                )}
              </Fragment>
            )
          }}
          className={styles.HomePageCard}
        />
      </section>
    );
  };

  const renderPlaystoreReviewsContainer = () => {
    const { cardTitle, reviewsList = [] } = playstoreReviewsSection || {};
    const middleIndex = Math.ceil(reviewsList.length / 2);
    const firstHalf = [...reviewsList].splice(0, middleIndex);
    const secondHalf = [...reviewsList].splice(middleIndex);
    return (
      <section className={`${styles.PlaystoreReviewsContainer} page-section-padding`}>
        <div className={styles.PlaystoreReviewsBlurContainer} />
        {cardTitle && (
          <Typography
            variant={TypographyVariants.HEADING_2}
            className={styles.PlaystoreReviewsTitle}
            style={{ fontSize: '28px', fontStyle: 'italic', fontFamily: TypographyFonts.LIBRE_BODONI, textAlign: 'center', marginTop: '-64px', marginBottom: '24px' }}
            tabletStyle={{ fontSize: '26px', marginBottom: '24px' }}
            mobileStyle={{ fontSize: '24px', marginBottom: '24px' }}
            dangerouslySetInnerHTML={{ __html: cardTitle }}
          />
        )}
        {reviewsList.length > 0 && (
          <div className={styles.PlaystoreReviewsListContainer}>
            <div className={styles.PlaystoreReviewsList}>
              {firstHalf.map((feature, index) => {
                return (
                  <Review key={index} {...feature} className={`${styles.PlaystoreReview} playstore-review`} reviewUserClassName={styles.PlaystoreReviewUser} />
                );
              })}
            </div>
            <div className={styles.PlaystoreReviewsList}>
              {secondHalf.map((feature, index) => {
                return (
                  <Review key={index} {...feature} className={`${styles.PlaystoreReview} playstore-review`} reviewUserClassName={styles.PlaystoreReviewUser} />
                );
              })}
            </div>
          </div>
        )}
      </section>
    );
  };

  const renderVideoPlayListContainer = () => {
    const { cardTitle, cardDescription, featuresList = [] } = videoPlayListSection || {};
    const responsiveSliderSettings = [
      { breakpoint: 1600, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 993, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ];
    const slickPrev = () => {
      if (videoPlayListimageSliderRef.current) {
        videoPlayListimageSliderRef.current.slickPrev();
      }
    };
    const slickNext = () => {
      if (videoPlayListimageSliderRef.current) {
        videoPlayListimageSliderRef.current.slickNext();
      }
    };
    const SlickArrowLeft = ({ ...props }) => (
      <div className={styles.HomePageCardSlidePrev} onClick={slickPrev}>
        <SliderLeftArrow className="slick-prev slick-arrow" {...props} />
      </div>
    );
    const SlickArrowRight = ({ ...props }) => (
      <div className={styles.HomePageCardSlideNext} onClick={slickNext}>
        <SliderRightArrow className="slick-next slick-arrow" {...props} />
      </div>
    );
    return (
      <section className={`${styles.VideoPlayListContainer} page-section-padding`}>
        {cardTitle ? (
          <Typography
            variant={TypographyVariants.HEADING_2}
            className={styles.HomePageCardTitle}
            style={{ fontSize: '36px', fontWeight: '700', color: 'var(--black)', textAlign: 'center' }}
            tabletStyle={{ fontSize: '32px' }}
            mobileStyle={{ fontSize: '26px' }}
            dangerouslySetInnerHTML={{ __html: cardTitle }}
          />
        ) : null}
        {cardDescription && (
          <Typography
            variant={TypographyVariants.HEADING_3}
            className={styles.VideoPlayListHeading}
            style={{ fontSize: '22px', fontFamily: TypographyFonts.LIBRE_BODONI, fontStyle: 'italic', textAlign: 'center', marginTop: '36px' }}
            tabletStyle={{ fontSize: '22px', marginTop: '24px' }}
            mobileStyle={{ fontSize: '22px', marginTop: '18px' }}
            dangerouslySetInnerHTML={{ __html: cardDescription }}
          />
        )}
        {featuresList.length > 0 && (
          <div className={styles.VideoPlayList}>
            <ImageSlider
              autoplay={false}
              dots={false}
              arrows={true}
              slidesToShow={4}
              slidesToScroll={1}
              lazyLoad={false}
              slides={
                featuresList.map((feature, index) => {
                  const { cardTitle, cardDescription, cardVideoTagName, cardVideoUrl, cardVideoAlt, cardVideoHeight, cardVideoWidth, cardVideoPosterUrl, cardVideoPosterUrlFallback, cardVideoPosterHeight, cardVideoPosterWidth, cardVideoMuted, cardVideoAutoplay, cardVideoControls, cardVideoDisablePictureInPicture, cardVideoAllowFullScreen, cardVideoLoop } = feature;
                  return (
                    <div key={index} className={styles.VideoPlayListItemContainer}>
                      {cardTitle && (
                        <Typography
                          variant={TypographyVariants.HEADING_3}
                          className={styles.VideoPlayListItemTitle}
                          style={{ fontSize: '20px', fontWeight: '700', marginBottom: '12px' }}
                          tabletStyle={{ fontSize: '18px' }}
                          mobileStyle={{ fontSize: '16px' }}
                          dangerouslySetInnerHTML={{ __html: cardTitle }}
                        />
                      )}
                      <Card
                        orientation={Orientation.VERTICAL}
                        imagePosition={{
                          mobile: Position.LEFT,
                          desktop: Position.LEFT
                        }}
                        alignment={{
                          justifyContent: FlexAlignment.FLEX_START,
                          alignItems: FlexAlignment.FLEX_START
                        }}
                        cardImage={{
                          className: styles.HomePageCardImage,
                          videoTagName: cardVideoTagName,
                          videoUrl: cardVideoUrl,
                          videoAlt: cardVideoAlt,
                          videoHeight: cardVideoHeight,
                          videoWidth: cardVideoWidth,
                          videoPosterUrl: cardVideoPosterUrl,
                          videoPosterUrlFallback: cardVideoPosterUrlFallback,
                          videoPosterHeight: cardVideoPosterHeight,
                          videoPosterWidth: cardVideoPosterWidth,
                          videoMuted: cardVideoMuted,
                          videoAutoplay: cardVideoAutoplay,
                          videoControls: cardVideoControls,
                          videoDisablePictureInPicture: cardVideoDisablePictureInPicture,
                          videoAllowFullScreen: cardVideoAllowFullScreen,
                          videoLoop: cardVideoLoop,
                          videoLoading: ImageLoading.LAZY
                        }}
                        cardContent={{
                          className: styles.HomePageCardContent,
                          title: (
                            cardDescription ? (
                              <Typography
                                variant={TypographyVariants.PARAGRAPH}
                                className={styles.HomePageCardTitle}
                                style={{ fontSize: '20px', fontWeight: '400', color: 'var(--black)' }}
                                tabletStyle={{ fontSize: '18px' }}
                                mobileStyle={{ fontSize: '16px' }}
                                dangerouslySetInnerHTML={{ __html: cardDescription }}
                              />
                            ) : null
                          )
                        }}
                        className={styles.HomePageCard}
                      />
                    </div>
                  );
                })
              }
              prevArrow={< SlickArrowLeft />}
              nextArrow={< SlickArrowRight />}
              responsive={responsiveSliderSettings}
              className={`${styles.HomePageCardSlider} video-play-list-slider`}
              ref={videoPlayListimageSliderRef}
            />
          </div>
        )}
      </section>
    );
  };

  const renderTalkToAnExpertContainer = () => {
    const { cardTitle, cardDescription, cardImageUrl, cardImageUrlFallback, cardImageAlt, cardImageHeight, cardImageWidth, buttonsList = [] } = talkToAnExpertSection || {};
    return (
      <section className={`${styles.TalkToAnExpertContainer} page-section-padding`}>
        <Card
          orientation={Orientation.HORIZONTAL}
          imagePosition={{
            mobile: Position.LEFT,
            desktop: Position.RIGHT
          }}
          alignment={{
            justifyContent: FlexAlignment.FLEX_START,
            alignItems: FlexAlignment.CENTER
          }}
          cardImage={{
            className: styles.HomePageCardImage,
            imageUrl: cardImageUrl,
            imageUrlFallback: cardImageUrlFallback,
            imageAlt: cardImageAlt,
            imageHeight: cardImageHeight,
            imageWidth: cardImageWidth
          }}
          cardContent={{
            className: styles.HomePageCardContent,
            title: (
              cardTitle ? (
                <Typography
                  variant={TypographyVariants.HEADING_2}
                  className={styles.HomePageCardDescription}
                  style={{ fontSize: '36px', fontFamily: TypographyFonts.LIBRE_BODONI, fontStyle: 'italic', color: 'var(--black)' }}
                  tabletStyle={{ fontSize: '32px' }}
                  mobileStyle={{ fontSize: '26px' }}
                  dangerouslySetInnerHTML={{ __html: cardTitle }}
                />
              ) : null
            ),
            description: (
              <Fragment>
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.HEADING_3}
                    className={styles.HomePageCardDescription}
                    style={{ fontSize: '26px', marginTop: '24px' }}
                    tabletStyle={{ fontSize: '24px' }}
                    mobileStyle={{ fontSize: '22px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {buttonsList.length > 0 && (
                  <div className={styles.HomePageCardButtonsContainer}>
                    {buttonsList.map((button, index) => {
                      const { type, htmlType, linkUrl, openInNewTab, text, onClick } = button;
                      return (
                        <Button
                          key={index}
                          type={type}
                          htmlType={htmlType}
                          className={styles.HomePageCardButton}
                          linkUrl={linkUrl}
                          openInNewTab={openInNewTab}
                          onClick={onClick}
                        >
                          {text}
                        </Button>
                      );
                    })}
                  </div>
                )}
              </Fragment>
            )
          }}
          className={styles.HomePageCard}
        />
      </section>
    );
  };

  return (
    <main className={styles.HomePageContainer}>
      {renderBoostConversionWhatsappContainer()}
      {renderPartnerLogosContainer()}
      {renderBoostConversionPersonalCommerceContainer()}
      {renderBuiltWithQuickSellContainer()}
      {renderCatalogueECommerceContainer()}
      {renderScaleBusinessOnWhatsappContainer()}
      {renderPowerfulFeaturesForB2bBusinessContainer()}
      {renderBuildYourOwnECommerceAppContainer()}
      {renderPlaystoreReviewsContainer()}
      {renderVideoPlayListContainer()}
      {renderTalkToAnExpertContainer()}
    </main>
  );

};

export default HomePage;
