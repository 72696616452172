import { forwardRef } from "react";

import Slider from "react-slick";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const ImageSlider = forwardRef((props, ref) => {
  const settings = {
    ...props,
    rows: typeof props.rows === 'number' ? props.rows : 1,
    dots: typeof props.dots === 'boolean' ? props.dots : false,
    infinite: typeof props.infinite === 'boolean' ? props.infinite : true,
    speed: typeof props.speed === 'number' ? props.speed : 500,
    slidesToShow: typeof props.slidesToShow === 'number' ? props.slidesToShow : 1,
    slidesToScroll: typeof props.slidesToScroll === 'number' ? props.slidesToScroll : 1,
    slides: props.slides || [],
    accessibility: typeof props.accessibility === 'boolean' ? props.accessibility : true,
    adaptiveHeight: typeof props.adaptiveHeight === 'boolean' ? props.adaptiveHeight : true,
    autoplay: typeof props.autoplay === 'boolean' ? props.autoplay : true,
    autoplaySpeed: typeof props.autoplaySpeed === 'number' ? props.autoplaySpeed : 3000,
    variableWidth: typeof props.variableWidth === 'boolean' ? props.variableWidth : false,
    arrows: typeof props.arrows === 'boolean' ? props.arrows : false,
    swipe: typeof props.swipe === 'boolean' ? props.swipe : true,
    swipeToSlide: typeof props.swipeToSlide === 'boolean' ? props.swipeToSlide : true,
    touchMove: typeof props.touchMove === 'boolean' ? props.touchMove : true,
    touchThreshold: typeof props.touchThreshold === 'number' ? props.touchThreshold : 5,
    waitForAnimate: typeof props.waitForAnimate === 'boolean' ? props.waitForAnimate : true,
    zIndex: typeof props.zIndex === 'number' ? props.zIndex : 1000,
    lazyLoad: typeof props.lazyLoad === 'boolean' ? (props.lazyLoad ? 'ondemand' : 'progressive') : 'progressive',
    pauseOnFocus: typeof props.pauseOnFocus === 'boolean' ? props.pauseOnFocus : false,
    pauseOnHover: typeof props.pauseOnHover === 'boolean' ? props.pauseOnHover : true,
    pauseOnDotsHover: typeof props.pauseOnDotsHover === 'boolean' ? props.pauseOnDotsHover : true,
    draggable: typeof props.draggable === 'boolean' ? props.draggable : true,
    prevArrow: props.prevArrow || <button type="button" className="slick-prev slick-arrow">Previous</button>,
    nextArrow: props.nextArrow || <button type="button" className="slick-next slick-arrow">Next</button>
  };
  return (
    <Slider ref={ref} {...settings} id={props.id} className={props.className} style={props.style ? props.style : {}}>
      {settings.slides}
    </Slider>
  );
});

ImageSlider.displayName = "ImageSlider";

export default ImageSlider;
